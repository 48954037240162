import logo from "@/../assets/logo.png"
import useChangePageTitle from "@/Shared/hooks/useChangePageTitle"
import { router, usePage } from "@inertiajs/react"
import { FormEvent, useState } from "react"
import { MdLogin } from "react-icons/md"

import { Box, Button, FormControl, Paper, Typography } from "@mui/material"

import EmailTextField from "@/Components/Common/Form/EmailTextField"
import PasswordTextField from "@/Components/Common/Form/PasswordField"

const Login = () => {
  useChangePageTitle("Zaloguj się")
  const [form, setForm] = useState({
    email: "",
    password: "",
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const key = e.target.id
    const value = e.target.value
    setForm(form => ({
      ...form,
      [key]: value,
    }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    router.post("/login", {
      email: form.email,
      password: form.password,
    })
  }
  const errors = usePage().props.errors
  return (
    <Box
      component={Paper}
      display={"flex"}
      flexDirection={"column"}
      p={2}
      marginY={"auto"}
      mx={"auto"}
      my={"auto"}
      justifyContent={"center"}
      alignItems={"center"}>
      <img src={logo} width={"200px"} />
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <EmailTextField
            name="email"
            label="Email"
            error={errors.email}
            handleChange={handleChange}
            value={form.email}
            required
          />
        </FormControl>
        <FormControl fullWidth>
          <PasswordTextField
            name="password"
            label="Hasło"
            error={errors.password}
            handleChange={handleChange}
            value={form.password}
            required
          />
        </FormControl>
        <Button
          startIcon={<MdLogin />}
          type="submit"
          fullWidth
          variant="contained"
          color="success"
          sx={{ mt: 2 }}>
          <Typography>Zaloguj</Typography>
        </Button>
      </form>
    </Box>
  )
}

export default Login
